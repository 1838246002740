import React, { useState, useEffect } from 'react';
import { Layout, theme } from 'antd';
import PostList from '../components/PostList/PostList';
import { fetchFilters, fetchPostsByFilter } from '../services/postService'; // 确保正确导入相关函数
import TagsList from '../components/TagsList/TagsList';

const { Sider, Content } = Layout;
const { useToken } = theme;

const Categories: React.FC = () => {

  const {token} = useToken()

  const [filters, setFilters] = useState<Map<string, string[]>>();
  
  const [posts, setPosts] = useState<any[]>([]); // 用于存储符合过滤条件的文章列表

  useEffect(() => {
    fetchFilters().then((filters: Map<string, string[]>) => {
      setFilters(filters)
    })
  }, []);

  const onSelectTag = async (tag: string, category: string) => {
    const filteredPosts = await fetchPostsByFilter(category, tag);
    setPosts(filteredPosts);
  }

  return (
    <Layout style={{ height: "calc(100vh - 64px)"  }}>
      <Sider 
        width={400} 
        style={{ 
          background: token.colorBgContainer, 
          paddingTop: '24px', 
          paddingLeft: '24px',
          paddingRight: '12px',
          position: 'relative', 
          height: 'calc(100vh - 64px)', 
          overflowY: 'auto',
        }}
      >
        { filters && <TagsList onSelect={(key, category)=>{ onSelectTag(key, category)}} tags={filters}></TagsList>} 
      </Sider>
      <Layout style={{ paddingTop:token.paddingLG, paddingLeft:token.paddingLG, overflowY: 'auto', height: 'calc(100vh - 80px)' }}>
        <Content>
          <PostList posts={posts} /> {/* 将符合过滤条件的文章列表传递给 PostList 组件 */}
        </Content>
      </Layout>
    </Layout>
  )
};

export default Categories;
