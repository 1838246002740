import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Flex, Tabs, theme } from 'antd';
import type { TabsProps } from 'antd';
import "./TagsList.css"


interface TagsListProps {
  onSelect: (selectedKeys: string, category: string) => void;
  tags: Map<string, string[]>;
}

const {useToken} = theme

const TagsList: React.FC<TagsListProps> = (props) => {

    const tagKeys: string[] = Array.from(props.tags.keys())
    const first = props.tags.get(tagKeys[0])
    const {token} = useToken()
    const [selecting, setSelecting] = useState<string>(first ? first[0] : "")

    useEffect(() => {
        for (let index = 0; index < tagKeys.length; index++) {
            const tagKey = tagKeys[index];
            const arr = props.tags.get(tagKey)
            console.log(arr, selecting, tagKey)
            if (arr?.includes(selecting)) {
                props.onSelect(selecting, tagKey)
            }
        }
    }, [selecting])

    const getItems: (() => TabsProps['items']) = () => {
        return tagKeys.map((lbl) => ({
                key: lbl,
                label: lbl,
                children:getChildrens(lbl),
        }))
    }

    const getTagBtn = (name:string) => {
        return <Button 
        key={name} 
        type={ selecting == name ? 'primary' : 'text' } 
        onClick={ () => {setSelecting(name)}}>
            {name}
        </Button>
    }

    const getChildrens = (cagtegory: string) => {
        const tags = props.tags.get(cagtegory)
        return (
            <Flex wrap gap="middle" style={{margin:token.marginLG}}>
                {tags?.map((tag) => getTagBtn(tag))}
            </Flex>
        )
    }

    return (
    <Tabs
    defaultActiveKey=""
    items={getItems()}
    indicator={{ size: (origin) => origin - 20, align: "center" }}
    centered
    />
    )
};

export default TagsList;
