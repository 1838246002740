// src/App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Button, Layout } from 'antd';
import NavBar from './components/NavBar/NavBar';
import FooterContent from './components/FooterContent/FooterContent';
import Home from './pages/Home';
import Categories from './pages/Categories';
import AboutMe from './pages/AboutMe';
import 'antd/dist/reset.css'; // 引入Ant Design样式
import "./App.css"
import Post from './pages/Post';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ScrollToHash from './components/ScrollToHash/ScrollToHash';

const { Header, Content, Footer } = Layout;

const App: React.FC = () => {
  useEffect(() => {
    document.title = "Kim's blog"
  }, [])
  return (
    <Router>
      <ScrollToHash />
      <Layout className="layout" style={{ minHeight: '100vh' }}>
      <CustomHeader></CustomHeader>
        <Content style={{ marginTop: "64px"}}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/about-me" element={<AboutMe />} />
            <Route path="/post/:title" element={<Post />} />
          </Routes>
        </Content>
        <Footer style={{bottom: 0, width: '100%', zIndex: 1, height: "80px" }}>
          <FooterContent />
        </Footer>
      </Layout>
    </Router>
  );
};

const CustomHeader: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const [lastPathname, setLastPathname] = useState<string | null>(null);

  useEffect(() => {
    if (lastPathname !== location.pathname) {
      setPreviousPath(lastPathname);
      setLastPathname(location.pathname);
    }
  }, [location.pathname, lastPathname]);

  const handleBack = () => {
    if (previousPath) {
      navigate(previousPath); // Go to the previous path without considering hash changes
    } else {
      navigate(-1); // Fallback to default back if there's no previous path stored
    }
  };

  return (
    <Header style={{ position: 'fixed', zIndex: 10, width: '100%', top: 0 }}>
      {location.pathname.startsWith('/post/') ? (
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
          style={{ color: '#fff', fontSize: '16px' }}
        >
          Back
        </Button>
      ) : (
        <NavBar />
      )}
    </Header>
  );
};

export default App;
