import { Avatar, Divider, Flex, Layout, theme, Typography } from 'antd';

const { useToken } = theme
const { Paragraph, Text } = Typography;

const AboutMe = () => {
    const {token} = useToken()
    const BASE_URL = process.env.REACT_APP_BASE_URL

    return (
        <Layout style={{ maxWidth:"906px", margin:"24px auto"}}>
            <h1 style={{color: token.colorPrimary}}>About Kim</h1>
            <Divider style={ {margin:"10px 0 24px 0" }} />
            <Paragraph>
            <Text>一名 7+ 年经验 iOS 软件工程师，目前就职于字节跳动。</Text>
            </Paragraph>

            <Paragraph>
            <Text>专注于音视频、图像编辑领域。</Text>
            </Paragraph>

            <Paragraph>
            <Text>喜欢小猫，喜欢运动，热爱生活。</Text>
            </Paragraph>

            <Paragraph>
            <Text>目前还是学习中的阶段，有不对的地方，请大佬们轻喷 & 给予斧正。</Text>
            </Paragraph>

            <Flex justify='space-between' align='center' style={ {borderTop: "1px solid white", borderBottom: "1px solid white", margin: "24px 0", padding: "64px 40px"} }>
            <Avatar size={96} src={ BASE_URL + "/assets/logo/logo192.png"} />
            <Text> 博客重构建设中... </Text>
            </Flex>
        </Layout>
    );
};

export default AboutMe;
