import { IPersonalInfo, IPersonalInfoLink } from "../interfaces/interfaces";

const BASE_URL = process.env.REACT_APP_BASE_URL + "/api";

// 请求函数 fetchPostByTitle 的实现
export const fetchPersonalInfo = async (): Promise<IPersonalInfo> => {
    try {
        const response = await fetch(`${BASE_URL}/personal-info`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const postData: any = await response.json();
        const parsedData: any[] = JSON.parse(postData.email)
        const personalInfoLinks: IPersonalInfoLink[] = parsedData.map(item => {
            // 因为解析后的数据结构为 { email: {...} } 或 { github: {...} }，所以需要进一步处理
            const key = Object.keys(item)[0]; // 获取 email 或 github
            const { id, iconUrl, url } = item[key]; // 解构出 id、iconUrl 和 url
            return { id, iconUrl, url } as IPersonalInfoLink; // 返回 IPersonalInfoLink 对象
        });
        // 构造 IPersonalInfo 对象
        const personalInfo: IPersonalInfo = {
            id: postData.id,
            name: postData.name,
            bio: postData.bio,
            avatarUrl: postData.avatar,
            links: personalInfoLinks // 将链接放入数组中
        };
        return personalInfo;
    } catch (error) {
        console.error('Failed to fetch personal info:', error);
        throw error;
    }
};