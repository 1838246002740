import Cherry from "cherry-markdown"


export const createEditorInstance = (ref, content, externals, handleChange, beforeImageMounted) => {
    const options = {
        el: ref.current,
        value: content,
        externals: externals,
        editor: {
            defaultModel: 'previewOnly',
        },
        callback: {
            afterChange: handleChange,
            urlProcessor: beforeImageMounted
        },
        previewer: {
            // theme: "dark"
        },
    }
    return new Cherry(options)
}
