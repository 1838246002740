// src/pages/Home.tsx
import React, { createElement, useEffect, useRef, useState } from 'react';
import { Button, Collapse, Layout, theme } from 'antd';
import PersonalInfo from '../components/PersonalInfo/PersonalInfo';
import PostList from '../components/PostList/PostList';
import { fetchPostsByRecommend } from '../services/postService';
import { IPost } from '../interfaces/interfaces';
import "./Home.css"
import Fireworks from '../components/Fireworks/Fireworks';
import ReactDOM from 'react-dom/client';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { Engine } from '@tsparticles/engine';
import { loadFull } from "tsparticles"; 
import type { Container } from "@tsparticles/engine";
import { loadFireworksPreset } from '@tsparticles/preset-fireworks';

const { Content, Sider } = Layout;
const { useToken } = theme;

const Home: React.FC = () => {
    const [recommendedPosts, setRecommendedPosts] = useState<IPost[]>([]);
    const [fireworks, setFireworks] = useState<React.ReactNode[]>([])
    const fireworksRef = useRef<{ startFireworks: () => void; stopFireworks: () => void }>(null);
    const {token} = useToken()
    
    const [ init, setInit ] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            // await loadFull(engine);
            // await loadSlim(engine);
            //await loadBasic(engine);
            await loadFireworksPreset(engine)
        }).then(() => {
            setInit(true);
        });
    }, []);

    useEffect(() => {
        console.log("inited")
    }, [init])

    useEffect(() => {
        const getRecommendedPosts = async () => {
            const posts = await fetchPostsByRecommend();
            setRecommendedPosts(posts);
        };

        getRecommendedPosts();
    }, []);

    const handleFireworkBtnClick = () => {
        fireworksRef.current?.startFireworks()
    }

    const fireworksJSX: React.ReactNode = (
        <>
        <Button
        type="primary"
        onClick={() => handleFireworkBtnClick()}
        style={{ color: '#fff', fontSize: '16px' }}
        >
        firework🎆
        </Button>
        <div className='firework-container' style={ {height: (400 - 32)} }>
            { init && (<Fireworks ref={fireworksRef} />)}
        </div>
        </>
    )

    return (
        <Layout className='site-content'>
            <Sider className="site-layout-background" style={{  }} width={"320px"}>
                <PersonalInfo />
            </Sider>
            <Layout style={{ marginLeft: token.marginXXL, marginRight: token.marginXXL, marginTop: token.marginXL,marginBottom: token.marginXL }}>
                <Content style={{ overflow: 'initial' }}>
                    <PostList posts={recommendedPosts} />
                </Content>
                <Content>
                <Collapse  ghost items={[{key:"", label:"Enjoy a fireworks show!", children:fireworksJSX}]} />
                </Content>

            </Layout>
        </Layout>
    )
}

export default Home;
