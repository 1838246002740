// src/components/Footer/Footer.tsx
import React from 'react';
import { Layout, theme } from 'antd';

const { Footer: AntFooter } = Layout;
const { useToken } = theme

const FooterContent : React.FC = () => {
    const {token} = useToken()
    return (
        <AntFooter style={{ textAlign: 'center', padding: '10px 50px' }}>
            <a href="http://beian.miit.gov.cn/" target="_blank" style={{color:token.colorPrimary}}>粤ICP备2021074147号-1</a>
        </AntFooter>
    );
};

export default FooterContent;
