// Fireworks.tsx
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Particles from '@tsparticles/react';
import type { Container } from "@tsparticles/engine";
import { theme } from 'antd';
import "./Fireworks.css"
import { count } from 'console';

interface FireworksProps {
  particlesLoaded?: (container?: Container) => void;
}

const { useToken } = theme;


const Fireworks = forwardRef((props: FireworksProps, ref) => {
  const { token } = useToken();
  const containerRef = useRef<Container | undefined>(undefined)
  const [emit, setEmit] = useState(false);

  const particlesLoaded = useCallback(async (container?: Container) => {
    containerRef.current = container;
    if (props.particlesLoaded) {
      props.particlesLoaded(container);
    }
  }, [props]);

useImperativeHandle(ref, () => ({
    startFireworks: () => {
      if (containerRef.current) {
        if (emit) {
            setEmit(false)
        } else {
            setEmit(true)
        }
      }
    },
    stopFireworks: () => {
      if (containerRef.current) {
        containerRef.current.stop();
      }
    },
  }));

return (
    <Particles
    id='tsp'
    className='fireworks'
    particlesLoaded={particlesLoaded}
    options={
        {
            preset:"fireworks",
            fullScreen:false,
            particles:{
                number:{value: 0},
            },
            sounds:{ enable: false },
            emitters: emit ? {
                direction: "top",
                life: { count: 0, duration: 0.1, delay: 0.1 },
                rate: { delay: 0.03, quantity: 1 },
                size: { width: 100, height: 0 },
                position: { y: 100, x: 50 },
              } : []
        }
    } />

);
});

export default Fireworks;
