import { theme, Tree, TreeDataNode, TreeProps, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./TocView.css";
import { ITocNode } from "../../../interfaces/interfaces";
import { DownOutlined } from '@ant-design/icons';

const { useToken } = theme;

interface TocViewProps {
  nodes: ITocNode[];
  onPress: (id: string) => void;
  activeId: string;
}

const TocView: React.FC<TocViewProps> = ({ nodes, onPress, activeId }) => {
  const { token } = useToken(); 
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);

  const onSelect: TreeProps['onSelect'] = (selectedKeys) => {
    const selectedKey = selectedKeys[0] as string;
    if (selectedKeys.length > 0) {
      window.location.hash = `#${selectedKey}`;
      onPress(selectedKey);
    }
  };

  useEffect(() => {
    const transformNodesToTreeData = (nodes: ITocNode[]): TreeDataNode[] => {
      return nodes.map((node) => ({
        title: (
          <Tooltip title={node.data.text} placement="right">
            <Typography.Text className="tree-node-text">
              {node.data.text}
            </Typography.Text>
          </Tooltip>
        ),
        key: node.data.id,
        children: node.childrens ? transformNodesToTreeData(node.childrens) : [],
        icon: node.data.text === activeId ? <DownOutlined /> : undefined,
      }));
    };

    setTreeData(transformNodesToTreeData(nodes));
  }, [nodes, activeId]);

  return (
    <>
      <Typography.Title level={4}>ToC</Typography.Title>
      {treeData.length > 0 && (
        <Tree
          showLine
          blockNode
          switcherIcon={<DownOutlined />}
          defaultExpandAll
          onSelect={onSelect}
          treeData={treeData}
          selectedKeys={[activeId]}
        />
      )}
    </>
  );
};

export default TocView;