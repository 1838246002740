import { ITocData, ITocNode } from "../../../interfaces/interfaces";


export const buildHeadingNodes = (datas: ITocData[]): ITocNode[] => {
  const result: ITocNode[] = [];
  const stack: { heading: ITocData; node: ITocNode }[] = [];

  datas.forEach((data) => {
      const newNode: ITocNode = { data: data, childrens: [] };
      
      // Pop elements from the stack if they are at a higher or equal level
      while (stack.length && stack[stack.length - 1].heading.level >= data.level) {
          stack.pop();
      }
      
      if (stack.length === 0) {
          // If the stack is empty, this node is a root node
          result.push(newNode);
      } else {
          // Otherwise, this node is a child of the current top node
          const parent = stack[stack.length - 1].node;
          parent.childrens.push(newNode);
      }
      
      // Push the new node onto the stack
      stack.push({ heading: data, node: newNode });
  });

  return result; // Return the resulting tree structure
};