import React from 'react';
import { Menu, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const { Header } = Layout;

const NavBar: React.FC = () => {

  const location = useLocation();
  
  // 根据当前路径判断应该选中哪个菜单项
  const selectedKey = location.pathname === '/categories' ? '2' : 
                      location.pathname === '/about-me' ? '3' : '1';

  return (
    <Header>
    <div className="logo" />
    <Menu mode="horizontal" selectedKeys={[selectedKey]}>
      <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
      <Menu.Item key="2"><Link to="/categories">Categories</Link></Menu.Item>
      <Menu.Item key="3"><Link to="/about-me">About Me</Link></Menu.Item>
    </Menu>
  </Header>
)

  }

export default NavBar;
