import Cherry from 'cherry-markdown';
import echarts from 'echarts';
import React, { useEffect, useRef } from 'react';
import 'cherry-markdown/dist/cherry-markdown.css';
import './MarkdownViewer.css';
import { createEditorInstance } from './MarkdownViewerConfig';
import { ITocData } from '../../interfaces/interfaces';

interface IMarkdownViewerProps {
    content?: string,
    handleViewerUpdate?: (str: string) => void
    onTocUpdated?: (datas: ITocData[]) => void
}
const MarkdownViewer: React.FC<IMarkdownViewerProps> = ({content, handleViewerUpdate, onTocUpdated}) => {
    
    const editorRef = useRef<HTMLDivElement | null>(null);
    const editorInstance = useRef<Cherry | null>(null);

    useEffect(() => {
      const afterChange = () => {
        if (editorInstance.current && onTocUpdated) {
          const toc = editorInstance.current.getToc()
          onTocUpdated(toc)
        }
      }

      const urlProcessor = (url: string, srcType: 'image' | 'audio' | 'video' | 'autolink' | 'link') => {
        if (srcType == 'image') {
          const baseURL = process.env.REACT_APP_BASE_URL + "/api/";
          const adjustedSrc = url ? new URL(url, baseURL).toString() : url;
          return adjustedSrc
        }
        return url
      }
      if (!editorInstance.current) {
        const ins = createEditorInstance(editorRef, content,  
        {
          echarts,
        }, afterChange, urlProcessor)
        ins.setTheme("viewer")
        editorInstance.current = ins
      }
        if (content) {
          editorInstance.current?.setMarkdown(content);
        }
        
      }, [content]);

      useEffect(() => {

      }, [editorRef.current])

      return (
        <>
         {editorRef && <div ref={editorRef} style={{height: "auto"}} />}
         </>
      )

}
  
  export default MarkdownViewer;