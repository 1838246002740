// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'antd/dist/reset.css'; // 确保引入了Ant Design的样式
import { ConfigProvider, theme, ThemeConfig } from 'antd';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

// const primaryColor = "#00fbff"
const primaryColor = "#b197fc"
const primaryColorAlpha = "#6600fbff"

const myTheme: ThemeConfig = {

  token: {
    fontSize: 20,
    colorPrimary: primaryColor,
    colorInfo: "#ef19be",
  },
  components:{
    Layout:{
      headerPadding: "0 0px",
      headerColor: "white",
      headerHeight: 64,
      siderBg: "#141414",
      footerBg: "#141414",
    },
    Tree: {
      directoryNodeSelectedBg: primaryColorAlpha,
      nodeSelectedBg: primaryColorAlpha,
      titleHeight: 30
    },
    Card: {
      paddingLG: 0
    },
    Button: {
      colorPrimary: "#5f3dc4"
    }
  },
  algorithm: theme.darkAlgorithm,
  cssVar:true
}

root.render(
  <React.StrictMode>
      <ConfigProvider theme={myTheme}>
    <App />
  </ConfigProvider>,
  </React.StrictMode>
);
