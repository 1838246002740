import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHash: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToHashElement = () => {
      const hash = location.hash;
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          // Disable any existing scroll behavior that might conflict
          window.history.scrollRestoration = 'manual';

          window.scrollTo({
            top: element.getBoundingClientRect().top + window.pageYOffset - 64, // Adjust for your header height
            behavior: 'smooth',
          });
        }
      }
    };

    // Initial scroll on load
    scrollToHashElement();

    // Listen for hash changes
    window.addEventListener('hashchange', scrollToHashElement);

    // Cleanup listener and restore scroll behavior on component unmount
    return () => {
      window.removeEventListener('hashchange', scrollToHashElement);
      window.history.scrollRestoration = 'auto'; // Restore default scroll behavior
    };
  }, [location]);

  return null;
};

export default ScrollToHash;