import React from 'react';
import { Link } from 'react-router-dom';
import { List, Tag, theme, Typography } from 'antd';
import { IPost } from '../../interfaces/interfaces';

interface PostListProps {
  posts: IPost[];
}

const { useToken } = theme;
const { Text } = Typography;

const PostList: React.FC<PostListProps> = ({ posts }) => {

  const {token} = useToken()

  return (
    <List
      itemLayout="vertical"
      pagination={{
        onChange: page => {
          console.log(page);
        },
        pageSize: 5,
      }}
      dataSource={posts}
      split={true}
      renderItem={post => (
        <Link to={`/post/${encodeURIComponent(post.title)}`}
         key={post.id}>
          <List.Item>
            <List.Item.Meta
              title={post.title}
              description={         
              <>
              <Tag color={token.colorPrimaryBorder}>{new Date(post.date).toLocaleDateString()}</Tag>
              {post.tags.map(tag => (
                <Tag key={tag.id} color={token.colorPrimaryBorder}>{tag.name}</Tag>
              ))}
              </>
              }
            />
        <Text type="secondary">
          {post.info}... {/* 显示部分内容 */}
        </Text>
                
            
          </List.Item>
        </Link>
      )}
    />
  );
};

export default PostList;
