// src/components/PersonalInfo/PersonalInfo.tsx
import React, { useEffect, useState } from 'react';
import { Avatar, Typography, Space, Divider, Button, theme } from 'antd';
import { IPersonalInfo, IPersonalInfoLink } from '../../interfaces/interfaces';
import { fetchPersonalInfo } from '../../services/personalInfoService';

import { MailOutlined, WechatOutlined, GithubOutlined, HomeOutlined, PhoneOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { useToken } = theme

const PersonalInfo: React.FC = () => {

  const [personalInfo, setPersonalInfo] = useState<IPersonalInfo | null>(null);
  const { token } = useToken()
  const BASE_URL = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    const getPersonalInfo = async () => {
      try {
        const info = await fetchPersonalInfo();
        setPersonalInfo(info);
      } catch (error) {
        console.error('Error fetching personal info:', error);
      }
    };
    getPersonalInfo();
  }, []);

  if (!personalInfo) {
    return <div>Loading...</div>;
  }

  return (
    <Space direction="vertical" align="center" style={{ width: '100%', paddingTop:token.marginXXL }}>
      <Avatar size={96} src={ BASE_URL + personalInfo.avatarUrl} />
      <Title level={4} style={{color: "white", paddingTop: token.marginXL}}>{personalInfo.name}</Title>
      <Text type='secondary'>一个喜欢猫的 iOS 程序员</Text>
      <Text type='secondary'>| iOS | 音视频 | 图像编辑 |</Text>
      <Divider />
      <Space direction="vertical" size="large" style={{ display: 'flex', margin:"0 10px" }} align='start'>
          <div>
            <HomeOutlined/> <a href='www.uiimage.com' style={{color:token.colorPrimary}}>  UImage.com</a>
          </div>
          <div>
          <PhoneOutlined/> <a style={{color:token.colorPrimary}}>  1324765****</a>
          </div>
          <div>
          <WechatOutlined/> <a style={{color:token.colorPrimary}}>  let**code</a>
          </div>
          <div>
          <MailOutlined/> <a style={{color:token.colorPrimary}}>  soulkimedison@gmail.com</a>
          </div>
          <div>
          <GithubOutlined/><a href='https://github.com/SourceKim' style={{color:token.colorPrimary}}>  SourceKim</a>
          </div>
        
      </Space>
    </Space>
  );
};

export default PersonalInfo;
