// src/services/postService.ts
import { IPost, ITag } from '../interfaces/interfaces';
import { mockPosts, mockTags, mockDates } from './mockData';

const BASE_URL = process.env.REACT_APP_BASE_URL + "/api";

// 请求可用的筛选项
export const fetchFilters = async (): Promise<Map<string, string[]>> => {
  try {
    const response = await fetch(`${BASE_URL}/posts/filters`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const filters = await response.json();
    const map: Map<string, string[]> = new Map(Object.entries(filters))
    return map;
  } catch (error) {
    console.error('Failed to fetch recommended posts:', error);
    throw error;
  }
};

// 请求推荐文章
export const fetchPostsByRecommend = async (): Promise<IPost[]> => {
  try {
    const response = await fetch(`${BASE_URL}/posts/recommend`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const posts: IPost[] = await response.json();
    return posts;
  } catch (error) {
    console.error('Failed to fetch recommended posts:', error);
    throw error;
  }
};

// 请求 filter 文章
export const fetchPostsByFilter = async (filter:string, option:string): Promise<IPost[]> => {
  try {
    const params = new URLSearchParams()
    params.append("filter", filter)
    params.append("option", option)
    const response = await fetch(`${BASE_URL}/posts/fetchPostsByFilter?${params.toString()}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const posts: IPost[] = await response.json();
    return posts;
  } catch (error) {
    console.error('Failed to fetch recommended posts:', error);
    throw error;
  }
};

export const fetchPostByTitle = async (title: string): Promise<IPost> => {
  try {
    const response = await fetch(`${BASE_URL}/posts/${title}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const post: IPost = await response.json();
    return post;
  } catch (error) {
    console.error('Failed to fetch post by title:', error);
    throw error;
  }
};
