import { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { fetchPostByTitle } from '../services/postService';
import { IPost, ITocData, ITocNode } from '../interfaces/interfaces';
import { Card, Skeleton, Typography, Tag, Divider, Layout, Menu, Affix, theme } from 'antd';
import MarkdownViewer from '../components/Markdown/MarkdownViewer';
import { buildHeadingNodes } from '../components/Markdown/TOC/TocUtils';
import TocView from '../components/Markdown/TOC/TocView';
import "./Post.css"

const { Title, Paragraph } = Typography;
const { Content, Sider } = Layout;
const { useToken } = theme

function Post() {
  const { title } = useParams<{ title: string }>();
  const [post, setPost] = useState<IPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [tocNode, setTocNode] = useState<ITocNode[]>([]);
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const {token} = useToken()

  // 调用接口，获取文章
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await fetchPostByTitle(decodeURIComponent(title ? title : ""));
        setPost(result);
      } catch (error) {
        console.error('Failed to fetch post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [title]);

  if (loading) {
    return <Skeleton active />;
  }

  if (!post) {
    return <Paragraph>No post found</Paragraph>
  }

  const handleViewerUpdate = (str: string) => {
    console.log(str)
  }

  const onTocUpdated = (datas: ITocData[]) => {
    setTocNode(buildHeadingNodes(datas))
  }

  const handleTocViewNodeClicked = (key: string) => {
    setActiveId(key)
  }

  return (
    <Layout style={{  }}>
      <Sider 
        width={320} 
        style={{ 
          background: token.colorBgContainer, 
          paddingTop: '24px', 
          paddingLeft: '24px',
          paddingRight: '12px',
          position: 'relative', 
          height: 'calc(100vh - 64px)', 
          overflowY: 'auto' 
        }}
      >

        <TocView nodes={tocNode} onPress={handleTocViewNodeClicked} activeId={activeId || (tocNode.length > 0 ? tocNode[0].data.id : "")} />

      </Sider>
      <Layout style={{ paddingTop:token.paddingLG, overflowY: 'auto', height: 'calc(100vh - 80px)' }}>
        <Content>
          <Card bordered={false} style={{ background: token.colorBgLayout}}>
            <div style={{paddingLeft: token.paddingLG, paddingRight: token.paddingLG}}>
            <Title>{post.title}</Title>
            <Paragraph>
              <Tag color={token.colorPrimaryBorder}>{new Date(post.date).toLocaleDateString()}</Tag>
              {post.tags.map(tag => (
                <Tag key={tag.id} color={token.colorPrimaryBorder}>{tag.name}</Tag>
              ))}
            </Paragraph>
            <Divider />
            </div>
            <MarkdownViewer content={post.content} handleViewerUpdate={handleViewerUpdate} onTocUpdated={onTocUpdated} />
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Post;